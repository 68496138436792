<template>
  <div class="position-show-config">
    <div class="list-content" v-loading="loading">
      <div style="min-height: 400px;">
        <div class="config-item" v-if="config !== null && tenantVersion !== 'school'">
          <div class="config-title">组织架构展示配置</div>
          <div class="config-content">
            <el-form class="small-form" size="small" label-width="70px" label-position="left">
              <el-form-item label="架构展示">
                <el-radio-group v-model="config" class="flex-col">
                  <el-radio :label="1">显示申请组织架构</el-radio>
                  <el-radio :label="2">显示一级组织架构</el-radio>
                  <el-radio :label="3">不显示组织架构</el-radio>
                  <el-radio :label="0">不显示</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <template v-if="tenantVersion === 'school'">
          <div v-if="config !== null" class="config-item">
            <div class="config-title">标签设置</div>
            <div class="config-content">
              <el-form class="small-form" size="small" label-width="110px" label-position="left">
                <el-form-item label="标签显示方式">
                  <el-radio-group v-model="config" class="flex-col">
                    <el-radio v-for="op in displayOptions" :key="op.value" :label="op.value">{{op.label}}</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item v-if="config !== 0 && config !== 4" label="校友会显示方式">
                  <el-radio-group v-model="showConfig" class="flex-col">
                    <el-radio v-for="op in schoolOptions" :key="op.value" :label="op.value">{{op.label}}</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-form>
            </div>
          </div>
        </template>
      </div>

      <div class="config-item" v-if="config !== null && tenantVersion === 'school'">
        <div class="config-title">效果预览</div>
        <div class="config-content" style="position: relative;">
          <img height="567px"  :src="`${baseImgUrl}/organization-admin/title.png`"/>
          <span style="position: absolute; left: 95px; bottom: 25px;">显示校友会名称 + 会内职务效果</span>
          <span style="position: absolute; left: 450px; bottom: 25px;">显示届别 + 学段 + 班级 + 校区名称</span>
        </div>
      </div>
    </div>

    <fixed-action-bar>
      <el-button type="primary" :loading="loading" @click="saveConfig">保存</el-button>
    </fixed-action-bar>
  </div>
</template>

<script>
  import FixedActionBar from "../../../../base/layout/FixedActionBar";
  import {getShowConfig, saveShowConfig} from "../../api/other-config";
  export default {
    components: {FixedActionBar},
    data() {
      return {
        loading: true,
        config: null,
        showConfig: null,
        baseImgUrl:'',
        options: [
          {value: 99, label: '显示校友会名称 + 会内职务'},
          {value: 4, label: '显示届别 + 学段 + 班级 + 校区名称'},
          {value: 0, label: '不显示'},
          {value: 1, label: '显示申请的校友会名称'},
          {value: 2, label: '显示最高层级的校友会名称'},
          {value: 3, label: '不显示校友会名称，仅显示会内职务'}
        ]
      }
    },
    computed: {
      tenantVersion() {
        return this.$store.getters.tenant.version;
      },
      displayOptions() {
        return this.options.filter((e) => e.value < 1 || e.value > 3)
      },
      schoolOptions() {
        return this.options.filter(e => e.value > 0 && e.value < 4)
      }
    },
    created() {
      this.getConfig();
      this.baseImgUrl = window.serverConfig.VUE_APP_IMG_URL
    },
    methods: {
      saveConfig() {
        // if (this.config === 99) {
        //   this.$message.error('请选择校友会显示方式')
        //   return
        // }
        this.loading = true;
        saveShowConfig({config: this.config === 99 ? this.showConfig : this.config}).then(res => {
          this.$message.success(res.msg);
          this.loading = false;
        }).catch(err => {
          this.loading = false;
        })
      },
      getConfig() {
        getShowConfig().then(res => {
          let config = parseInt(res.data)
          if (this.tenantVersion === 'school' && this.displayOptions.filter(el => el.value === config).length) {
            this.showConfig = config
            this.config = 99
          } else if (this.tenantVersion === 'school') {
            this.showConfig = config
            this.config = 99
          } else {
            this.config = config
          }
          this.loading = false;
        }).catch(err => {
          this.loading = false;
        })
      }
    },
  }
</script>

<style lang="scss" scoped>
.position-show-config {
  .list-item {
    min-height: 100px;
  }

  .config-item + .config-item {
    margin-top: 79px;
  }

  .config-item {
    padding-top: 20px;
    .config-title {
      padding-left: 8px;
      font-size: 16px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.85);
      line-height: 16px;
      border-left: 3px #3479ef solid;
    }
    .config-content {
      margin-top: 32px;
      padding-left: 11px;
      .switch-config {
        display: flex;
        align-items: center;
        margin-bottom: 12px;
        .form-title {
          font-size: 14px;
          color: rgba(0, 0, 0, 0.85);
          line-height: 14px;
          margin-right: 15px;
        }
      }
      .form-tip {
        display: flex;
        align-items: center;
        font-size: 12px;
        color: #999;
        line-height: 12px;
        span + span {
          margin-left: 4px;
        }
      }
      .suffix {
        line-height: 34px;
        margin-left: 12px;
        color: rgba(0, 0, 0, 0.85);
      }

      .el-input-number {
        max-width: 140px;
      }
    }
  }

  .flex-col {
    .el-radio {
      line-height: 32px;
    }
  }
}
</style>
